import { createSelector } from 'reselect';

import { isChromeOS } from '~/libs/device';

import { selectActiveFeatures } from './features/selectors';

export const selectIsServiceWorkerEnabled = createSelector(
  selectActiveFeatures,
  (activeFeatures) => {
    return (
      activeFeatures.includes('service_workers_enabled') ||
      (activeFeatures.includes('service_workers_always_for_chromeos') &&
        isChromeOS())
    );
  }
);
